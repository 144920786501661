<template>
    <div class="password-login">
        <el-form ref="loginForm" class="login-form" :model="loginForm" :rules="loginRules" label-width="0px">
            <!-- 账号 -->
            <el-form-item prop="userName">
                <div class="login-form-item login-form-input" :style="{'border-color': errObjectStatus.userName ? '#e63a2e' : '#D9D9D9'}">
                    <i class="login-form-input-icon el-icon-user"></i>
                    <el-input v-model="loginForm.userName" placeholder="账号"></el-input>
                </div>
            </el-form-item>
            <!-- 密码 -->
            <el-form-item prop="password">
                <div class="login-form-item login-form-input" :style="{'border-color': errObjectStatus.password ? '#e63a2e' : '#D9D9D9'}">
                    <i class="login-form-input-icon el-icon-lock"></i>
                    <el-input
                        :type="showPassword ? 'text' : 'password'"
                        v-model="loginForm.password"
                        placeholder="密码"
                        @keyup.enter.native="handleLogin"
                    >
                        <i slot="suffix" @click="switchPassword">
                            <img
                                v-if="showPassword"
                                :src="showView"
                                class="input-icon"
                                alt="show"/>
                            <img
                                v-else
                                :src="closeView"
                                class="input-icon"
                                alt="close"/>
                        </i>
                    </el-input>
                </div>
            </el-form-item>
            <!-- 图片验证码 -->
            <el-form-item prop="code">
                <div class="login-form-item login-form-input" :style="{'border-color': errObjectStatus.code ? '#e63a2e' : '#D9D9D9'}">
                    <i class="login-form-input-icon el-icon-postcard"></i>
                    <el-input
                        v-model="loginForm.code"
                        placeholder="验证码"
                        @keyup.enter.native="handleLogin"
                    ></el-input>
                    <!-- 网络图片验证码 -->
                    <img
                        v-if="loginForm.codeUrl"
                        slot="suffix"
                        class="login-form-item-code"
                        :src="loginForm.codeUrl"
                        alt="code"
                        @click="handlePictureVerificationCodeClick"
                    >
                    <!-- 静态图片验证码占位 -->
                    <img
                        v-else
                        slot="suffix"
                        class="login-form-item-code"
                        :src="imgVerificationCode"
                        alt="code"
                    >
                </div>
            </el-form-item>
            <!-- 记住密码 -->
            <div class="remember-password">
                <el-checkbox v-model="loginForm.rememberPassword" v-if="templateInfo.rememberme == '1'">记住登录状态</el-checkbox>
                <span v-else></span>
                <el-button type="text" class="text" @click="forgottenPassword">忘记密码</el-button>
            </div>
            <!-- 登录按钮 -->
            <div
                class="login-button"
                :class="{ 'login-button_loading': loginButtonLoading }"
                @click="handleLogin">
                <i v-show="loginButtonLoading" class="el-icon-loading"></i>
                <span>登  录</span>
            </div>
        </el-form>
    </div>
</template>

<script>
export default {
    name: 'PasswordLogin',
    components: {},
    props: {
        templateInfo: {
            type: Object
        },
        loginForm: {
            type: Object
        },
        loginRules: {
            type: Object
        },
        loginButtonLoading: {
            type: Boolean
        }
    },
    data() {
        return {
            codeUrl: '',
            showPassword: false,
            errObjectStatus: {
                userName: false,
                password: false,
                code: false
            }
        }
    },
    computed: {
        imgQrcodeBtn() {
            return require('@/assets/images/loginPageTemplate/theme1/login_box_qrcode_btn.png')
        },
        imgVerificationCode() {
            return require('@/assets/images/loginPageTemplate/theme1/login_box_verification_code.png')
        },
        closeView () {
            return require('@/assets/images/login/close-view.png')
        },
        showView () {
            return require('@/assets/images/login/show-view.png')
        }
    },
    methods: {
        // 刷新验证码图片
        async referhCode() {
            this.paramDate = +new Date();
            this.codeUrl = await serviceDownload(this.paramDate);
        },
        handleLoginMethodSwitch(loginMethod) {
            this.$eventDispatch('loginMethodSwitch', loginMethod);
        },
        handlePictureVerificationCodeClick() {
            this.$eventDispatch('pictureVerificationCodeClick', this.loginForm.codeUrl);
        },
        handleLogin() {
            this.$refs.loginForm.validate((valid, object) => {
                for(let key in this.errObjectStatus) {
                    this.errObjectStatus[key] = false;
                }
                if (valid) {
                    this.$eventDispatch('login', this.loginForm);
                } else {
                    this.setLoginRulesError(object)
                    this.$eventDispatch('loginRulesErr')
                }
            })
        },
        setLoginRulesError(errObject) {
            const errGroup = Object.keys(errObject);
            errGroup.forEach(key => {
                this.errObjectStatus[key] = true;
            })
            
        },
        forgottenPassword () {
            this.$eventDispatch('forgottenPassword')
        },
        switchPassword () {
            this.showPassword = !this.showPassword;
        }
    }
}
</script>

<style lang="scss" scoped>
$coefficient: var(--coefficient, 1px);

.password-login {
    position: relative;
    width: 100%;

    .qrcode-btn {
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100 * #{$coefficient});
        height: calc(100 * #{$coefficient});
        object-fit: contain;
        display: inline-block;
        cursor: pointer;
    }

    .login-header {
        display: flex;
        align-items: flex-end;

        .login-title_welcome {
            font-size: calc(40 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #427AFA;
        }

        .login-title-sel {
            width: 238px;
            height: 45px;
            position: relative;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            background: rgba(255,255,255,0.62);
            font-size: calc(22 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            cursor: pointer;
            box-shadow: 0px 0px 20px 0px rgba(24,42,132,0.3);

        }
        .login-title-sel::after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            bottom: 0;
            background-color: #FFFFFF;
        }
        .login-title-sel::after {
            right: -10px;
            background: radial-gradient(circle at 10px 0, transparent 10px,#FFFFFF 10px);
        }
        .login-title {
            width: 240px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            border-top-right-radius: 10px;
            background: rgba(255,255,255,0.62);
            font-size: calc(18 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
        }
        .login-title_active {
            width: 240px;
            height: 55px;
            line-height: 55px;
            text-align: center;
            font-size: calc(18 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #5873FA;
            background-color: #FFFFFF;
            box-shadow: 0px -7px 11px 0px rgba(24,42,132,0.3);
        }
    }

    .login-form {
        padding: calc(47 * #{$coefficient}) calc(41 * #{$coefficient}) 0;
        background-color: #FFFFFF;
        ::v-deep .el-form-item {
            margin-bottom: 0;

            +.el-form-item {
                margin-top: calc(24 * #{$coefficient});
            }

            .el-form-item__error {
                padding-top: calc(3 * #{$coefficient});
                font-size: calc(15 * #{$coefficient});
            }
        }

        ::v-deep .el-input {
            font-size: calc(18 * #{$coefficient});

            .el-input__inner {
                border: unset;
                padding: 0;
                height: auto;
                line-height: 1;
            }

            .el-input__suffix {
                .el-input__suffix-inner {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 100%;
                }

                .el-input__icon {
                    width: auto;
                    line-height: 1;
                    font-size: calc(16 * #{$coefficient});
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    +.el-input__icon {
                        margin-left: calc(10 * #{$coefficient});
                    }
                }

                .el-input__validateIcon {
                    display: none;
                }
            }
        }
        ::v-deep .el-input--suffix {
            .el-input__inner {
                padding-right: calc(36 * #{$coefficient});
            }
        }

        .login-form-item {
            display: flex;
            align-items: center;
            .input-icon {
                width: calc(21 * #{$coefficient});
                margin-top: calc(10 * #{$coefficient});
            }
        }

        .login-form-input {
            height: calc(56 * #{$coefficient});
            padding: 0 calc(20 * #{$coefficient}) 0;
            border: 1px solid #D9D9D9;
            border-radius: calc(8 * #{$coefficient});
            box-sizing: border-box;
            display: flex;
            align-items: center;

            .login-form-input-icon {
                color: #C9C9C9;
                font-size: calc(22 * #{$coefficient});
                padding-right: calc(12 * #{$coefficient});
            }

            ::v-deep .el-input {
                // 解决回填后带背景色问题
                .el-input__inner:-webkit-autofill,
                .el-input__inner:-webkit-autofill:hover,
                .el-input__inner:-webkit-autofill:focus,
                .el-input__inner:-webkit-autofill:active {
                    -webkit-transition-delay: 99999s;
                    -webkit-transition: color 99999s ease-out,
                    background-color 99999s ease-out;
                }
            }
        }
        .login-form-input:hover {
            border-color: #3C7FFF;
        }

        .login-form-item-code {
            width: calc(103 * #{$coefficient});
            height: calc(36 * #{$coefficient});
            object-fit: contain;
            display: inline-block;
            margin-left: calc(10 * #{$coefficient});
            cursor: pointer;
        }

        .remember-password {
            display: flex;
            justify-content: space-between;
            margin-top: calc(16 * #{$coefficient});
            line-height: 1;

            ::v-deep .el-checkbox {
                display: flex;
                flex-direction: row;
                align-items: center;

                .el-checkbox__inner {
                    width: calc(16 * #{$coefficient});
                    height: calc(16 * #{$coefficient});
                    min-width: 9px;
                    min-height: 9px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &::after {
                        top: calc(2 * #{$coefficient});
                        left: calc(5 * #{$coefficient});
                        width: calc(3 * #{$coefficient});
                        height: calc(7 * #{$coefficient});
                    }
                }
                .el-checkbox__label {
                    padding-left: calc(10 * #{$coefficient});
                    line-height: calc(19 * #{$coefficient});
                    font-size: calc(16 * #{$coefficient});
                }
            }

            .text {
                /deep/ span {
                    font-size: calc(16 * #{$coefficient});
                }
            }
        }

        .login-button {
            width: 100%;
            height: calc(56 * #{$coefficient});
            background: linear-gradient(90deg, #3E7DFA 0%, #5773FA 20%, #72C3FC 100%);
            border-radius: calc(8 * #{$coefficient});
            margin-top: calc(18 * #{$coefficient});
            text-align: center;
            line-height: calc(56 * #{$coefficient});
            font-size: calc(20 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            cursor: pointer;

            .el-icon-loading {
                margin-right: calc(6 * #{$coefficient});
            }
        }
        .login-button:hover {
            opacity: .5;
        }
        .login-button_loading {
            cursor: not-allowed;
            pointer-events: none;
            filter: opacity(30%);
            -webkit-filter: opacity(30%);
        }
    }
    .login-qrcode {
        margin-top: calc(109 * #{$coefficient});
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .login-qrcode-btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            +.login-qrcode-btn {
                margin-left: calc(110 * #{$coefficient});
            }

            img {
                width: calc(86 * #{$coefficient});
                height: calc(86 * #{$coefficient});
                object-fit: contain;
                display: inline-block;
            }

            span {
                font-size: calc(16 * #{$coefficient});
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #05042A;
                margin-top: calc(17 * #{$coefficient});
            }
        }
    }
    ::v-deep .el-button {
        height: calc(32 * #{$coefficient});
        padding: calc(8 * #{$coefficient}) calc(17 * #{$coefficient}) !important;
    }
}
</style>
