<template>
    <div class="login-qrcode">
        <div class="tips">
            快捷登录
        </div>
        <div class="qrcode-group">
            <div v-if="templateInfo.loginMethod.includes('2')" class="login-qrcode-btn"
                @click="handleOtherLoginClick('WeChat')">
                <img :src="imgWeChat" alt="weChat">
            </div>
            <div v-if="templateInfo.loginMethod.includes('3')" class="login-qrcode-btn"
                @click="handleOtherLoginClick('DingTalk')">
                <img :src="imgDingTalk" alt="dingTalk">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "LoginQrcode",
    props: {
        templateInfo: {
            type: Object
        },
        loginForm: {
            type: Object
        }
    },
    data() {
        return {}
    },
    computed: {
        imgWeChat() {
            return require('@/assets/images/loginPageTemplate/theme1/login_box_qrcode_wechat.png')
        },
        imgDingTalk() {
            return require('@/assets/images/loginPageTemplate/theme1/login_box_qrcode_dingtalk.png')
        }
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        handleLoginWithAccount() {
            this.$eventDispatch('loginMethodSwitch', this.templateInfo.loginMethod.includes('1') ? '1' : '4');
        },
        handleOtherLoginClick(type) {
            this.$eventDispatch('otherLoginClick', type);
        }
    }
}
</script>

<style lang="scss" scoped>
$coefficient: var(--coefficient, 1px);

.login-qrcode {
    display: flex;
    flex-direction: column;
    .tips {
        width: 100%;
        height: calc(44 * #{$coefficient});
        margin-top: calc(5 * #{$coefficient});
        line-height: calc(44 * #{$coefficient});
        position: relative;
        text-align: center;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: calc(14 * #{$coefficient});
        color: #333333;
    }

    .tips::before,
    .tips::after{
        content: '';
        position: absolute;
        top: 50%;
        width: 25%;
        height: 1px;
    }
    .tips::before {
        left: 0;
        transform: translateX(70%);
        background: -webkit-linear-gradient(right, #D9D9D9 1%, #D9D9D9 50%, #fff 100%);
    }

    .tips::after {
        right: 0;
        transform: translateX(-70%);
        background: -webkit-linear-gradient(left, #D9D9D9 1%, #D9D9D9 50%, #fff 100%);
    }

    .qrcode-group {
        display: flex;
        padding: 0 calc(114 * #{$coefficient});
        .login-qrcode-btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;
            cursor: pointer;

            +.login-qrcode-btn {
                justify-content: space-between;
                flex: 1;
            }

            img {
                width: calc(55 * #{$coefficient});
                height: calc(55 * #{$coefficient});
                object-fit: contain;
                display: inline-block;
            }
        }
    }
}
</style>