<template>
    <div class="common-login">
        <div class="login-header" :style="loginHeader">
            <div
                v-if="templateInfo.loginMethod.filter(e => ['1', '4'].includes(e)).length === 1"
                class="login-title_welcome"
            >欢迎登录</div>
            <template v-else>
                <div :class="accountPassword" @click="changeLogin('1')">账号密码登录</div>
                <div :class="mobileQuickLogin" @click="changeLogin('2')">手机快捷登录</div>
            </template>
        </div>
        <div class="login-main" :style="loginMain">
            <!-- <div class="shadow-ground">
                <div class="left" :style="styLeft"></div>
                <div class="right" :style="styRight"></div>
            </div> -->
            <password-login
                v-if="selTab == '1'"
                :templateInfo="templateInfo"
                :loginForm="loginForm"
                :loginRules="loginRules"
                :loginButtonLoading="loginButtonLoading"
            >
            </password-login>
            <VerificationCodeLogin
                v-if="selTab == '2'"
                :templateInfo="templateInfo"
                :loginForm="loginForm"
                :loginRules="loginRules"
                :loginButtonLoading="loginButtonLoading"
            >
            </VerificationCodeLogin>
            <login-qrcode
                v-if="templateInfo.loginMethod.find(e => ['2', '3'].includes(e))"
                :templateInfo="templateInfo"
                :loginForm="loginForm"
            >
            </login-qrcode>
        </div>
    </div>
</template>

<script>
import PasswordLogin from './PasswordLogin.vue';
import VerificationCodeLogin from './VerificationCodeLogin.vue';
import LoginQrcode from './LoginQrcode.vue';
import { themeList } from "@/assets/data/LoginPageTemplate/themeList.js"
export default {
    name: 'CommonLogin',
    components: {
        PasswordLogin,
        VerificationCodeLogin,
        LoginQrcode
    },
    props: {
        templateInfo: {
            type: Object
        },
        loginForm: {
            type: Object
        },
        loginRules: {
            type: Object
        },
        loginButtonLoading: {
            type: Boolean
        },
        loginPageInfo: {
            type: Object
        },
    },
    data() {
        return {
            codeUrl: '',
            showPassword: false,
            selTab: '1',
        }
    },
    computed: {
        accountPassword() {
            let fixing = 'left';
            return this.selTab == '1' ?  ['login-title-sel', fixing] : ['login-title', fixing];
        },
        mobileQuickLogin() {
            let fixing = 'right';
            return this.selTab != '1' ?  ['login-title-sel', fixing] : ['login-title', fixing];
        },
        loginHeader() {
            let noHasProjectMark = this.templateInfo.loginMethod.filter(e => ['1', '4'].includes(e)).length === 1;
            if (noHasProjectMark) {
                return {
                    'background-color': '#FFF'
                }
            }
        },
        loginBoxOriginalWidth() {
            return themeList.find(e => e.id === this.loginPageInfo.theme).loginBox.width
        },
        loginMain() {
            console.log(this.loginBoxOriginalWidth,'this.loginPageInfo.loginBox.width==')
            let hasQr = this.templateInfo.loginMethod.find(e => ['2', '3'].includes(e));
            let noHasProjectMark = this.templateInfo.loginMethod.filter(e => ['1', '4'].includes(e)).length === 1;
            let bi = this.loginPageInfo.loginBox.width / this.loginBoxOriginalWidth;
            let gh = 519 * bi;
            let dh = 442 * bi;
            let br = 10 * bi;
            if (this.selTab == '1' &&  hasQr && !noHasProjectMark) {
                return {
                    'height': `${gh}px`,
                    'border-top-right-radius': `${br}px`
                }
            } 
            if (this.selTab == '2' &&  hasQr && !noHasProjectMark){
                return {
                    'height': `${gh}px`,
                    'border-top-left-radius': `${br}px`
                }
            }

            if (this.selTab == '1' && !noHasProjectMark) {
                return {
                    'height': `${dh}px`,
                    'border-top-right-radius': `${br}px`
                }
            }

            if (this.selTab == '2' && !noHasProjectMark) {
                return {
                    'height': `${dh}px`,
                    'border-top-left-radius': `${br}px`
                }
            }

            if (this.selTab == '1' || this.selTab == '2') {
                return {
                    'height': `${dh}px`,
                }
            }
        },
        styLeft() {
            if (this.selTab != '1') {
                return {
                    'box-shadow': '0px -5px 11px rgba(24,42,132,0.13)'
                }
            }
        },
        styRight() {
            if (this.selTab != '2') {
                return {
                    'box-shadow': '0px -5px 11px rgba(24,42,132,0.13)'
                }
            }
        },
        imgQrcodeBtn() {
            return require('@/assets/images/loginPageTemplate/theme1/login_box_qrcode_btn.png')
        },
        imgVerificationCode() {
            return require('@/assets/images/loginPageTemplate/theme1/login_box_verification_code.png')
        },
        closeView () {
            return require('@/assets/images/login/close-view.png')
        },
        showView () {
            return require('@/assets/images/login/show-view.png')
        }
    },
    methods: {
        // 刷新验证码图片
        async referhCode() {
            this.paramDate = +new Date();
            this.codeUrl = await serviceDownload(this.paramDate);
        },
        /**
         * @Description: 切换登录方式
         * @Author: 武东超
         * @Date: 2024-08-28 14:20:31
         * @param {*} type
         */        
        changeLogin(type) {
            this.selTab = type;

            switch(type) {
                case '1':
                    this.handleLoginMethodSwitch('1');
                    break;
                case '2':
                    this.handleLoginMethodSwitch('4');
                    break;
                case '3':
                    break;
                case '4':
                    break;
                default:
                    break;
            }
        },
        handleLoginMethodSwitch(loginMethod) {
            this.$eventDispatch('loginMethodSwitch', loginMethod);
        },
        handlePictureVerificationCodeClick() {
            this.$eventDispatch('pictureVerificationCodeClick', this.loginForm.codeUrl);
        },
        handleLogin() {
            this.$refs.loginForm.validate((valid) => {
                // console.log("[handleLogin]valid:", valid)
                if (valid) {
                    this.$eventDispatch('login', this.loginForm);
                }
            })
        },
        switchPassword () {
            this.showPassword = !this.showPassword;
        }
    }
}
</script>

<style lang="scss" scoped>
$coefficient: var(--coefficient, 1px);

.common-login {
    position: relative;
    width: 100%;
    height: 100%;
    .login-header {
        width: 100%;
        display: flex;
        align-items: flex-end;

        .login-title_welcome {
            width: 100%;
            text-align: center;
            font-size: calc(40 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #427AFA;
        }

        .login-title-sel {
            position: relative;
            width: 49.79%;
            height: calc(59 * #{$coefficient});
            line-height: calc(59 * #{$coefficient});
            text-align: center;
            font-size: calc(18 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #5873FA;
            background-color: #FFFFFF;
            border-top-right-radius: calc(10 * #{$coefficient});
            // box-shadow: 0px -7px 11px 0px rgba(24,42,132,0.13);
            cursor: pointer;
        }

        .login-title-sel.left {
            border-top-left-radius: calc(10 * #{$coefficient});
        }

        .login-title-sel.right {
            border-top-left-radius: calc(10 * #{$coefficient});
        }

        .login-title-sel::after {
            content: "";
            position: absolute;
            width: calc(10 * #{$coefficient});
            height: calc(10 * #{$coefficient});
            bottom: 0;
            background-color: #FFFFFF;
        }

        .login-title-sel.left::after {
            right: calc(-10 * #{$coefficient});
            background: radial-gradient(circle at calc(10 * #{$coefficient}) 0, transparent calc(10 * #{$coefficient}),#FFFFFF calc(10 * #{$coefficient}));
        }

        .login-title-sel.right::after {
            left: calc(-10 * #{$coefficient});
            background: radial-gradient(calc(10 * #{$coefficient}) circle at 0 0, transparent calc(10 * #{$coefficient}),#FFFFFF calc(10 * #{$coefficient}));
        }

        .login-title {
            width: 50.21%;
            height: calc(49 * #{$coefficient});
            position: relative;
            line-height: calc(49 * #{$coefficient});
            text-align: center;
            border-top-right-radius: 10px;
            background: rgba(255,255,255,0.62);
            font-size: calc(18 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            cursor: pointer;
        }

        .login-title::before {
            content: "";
            position: absolute;
            width: calc(10 * #{$coefficient});
            height: calc(10 * #{$coefficient});
            bottom: calc(-10 * #{$coefficient});
            right: 0;
            background: rgba(255,255,255,0.62);
        }

        .login-title::after {
            content: "";
            position: absolute;
            width: calc(10 * #{$coefficient});
            height: calc(10 * #{$coefficient});
            bottom: calc(-10 * #{$coefficient});
            left: 0;
            background: rgba(255,255,255,0.62);
        }

        .login-title.left {
            border-top-left-radius: calc(10 * #{$coefficient});
        }
    }

    .login-main {
        width: 100%;
        overflow: hidden;
        background-color: #FFFFFF;
        position: relative;
        border-bottom-left-radius: calc(10 * #{$coefficient});
        border-bottom-right-radius: calc(10 * #{$coefficient});
        margin-top: -1px;
        .shadow-ground {
            width: 100%;
            height: 1px;
            display: flex;
            background-color: #FFFFFF;
            .left, .right {
                width: 50%;
                height: 100%;
                background-color: #FFFFFF;
            }
        }
    }
}
</style>
